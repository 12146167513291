<template>
  <div class="error-page">
    <div class="container">
      <div class="error-page__content">
        <span>#404</span>
        <p>Упс... Что-то пошло не так</p>
        <router-link :to="{ name: 'Home' }">
          <Button red>НА ГЛАВНУЮ</Button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
export default {
  name: "404",
  components: { Button },
};
</script>

<style lang="scss">
.error-page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--btn-blue-color1);
    margin: 100px 0;
    & > span {
      font-size: 260px;
      line-height: 320px;
      font-weight: 700;
    }
    p {
      margin-bottom: 100px;
      font-size: 50px;
      line-height: 75px;
      font-weight: 600;
    }
  }
  @include adaptive(tablet-small) {
    &__content {
      & > span {
        font-size: 30px;
        line-height: 50px;
      }
      p {
        margin-bottom: 60px;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
</style>
